import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

export default (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const validateEmail = () => {
        let emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        if (email === '' || !emailRegex.test(email)) {
            setIsValidEmail(false);
            return false;
        }
        setIsValidEmail(true);
        return true;
    }

    const submit = async () => {
        //alert(JSON.stringify(state));
        if (firstName === '' || message === '' || phone === '') {
            toast.warn("Fill out all necessary fields.");
            return;
        }
        if (!validateEmail()) {
            toast.warn("Invalid email.");
            return;
        }
        try {
            let response = await axios.post("https://api.astute.health/sendContactEmail", {
                firstName,
                lastName,
                phone,
                email,
                message
            });
            if (response.status !== 200) {
                // return null;
                // TODO: error toast
                console.log("ERROR WITH STATUS:", response.status);
            }
        } catch (e) {
            console.log(e);
        }
        toast.success("Email Sent.");
        clear();
    }

    const clear = () => {
        setFirstName("");
        setLastName("");
        setPhone("");
        setIsValidEmail(true);
        setEmail("");
        setMessage("");
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <div className="contact-form-wrapper">
                <h1 className="heading-17">Contact Us</h1>
                <div className="columns-4 w-row">
                    <div className="column-8 w-col w-col-6 w-col-stack">
                        <h2 className="heading-2 contact-from-h2">Please fill out the form, and a member of our team will reach out
                            to you promptly. We look forward to hearing from you! Alternatively, you may contact us at
                            {" "}<a className="solutions-tabs_link-text-2" style={{ fontWeight: 'bold' }} href="mailto:info@astute.health">info@astute.health</a>
                        </h2>
                    </div>
                    <div className="column-7 w-col w-col-6 w-col-stack">
                        <section id="contact-form" className="contact-form wf-section">
                            <div className="container-4 w-container">
                                <div className="form-block w-form">
                                    {/* <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-cf9" aria-label="Email Form"> */}
                                    <div className="form-subwrapper-cf9">
                                        <input type="text"
                                            className="text-field-cf9 w-input"
                                            maxLength={256}
                                            name="Name-8"
                                            data-name="Name 8"
                                            placeholder="First name*"
                                            id="Name-8" required={true}
                                            value={firstName}
                                            onChange={(text) => setFirstName(text.target.value)}
                                        />
                                        <input
                                            type="text"
                                            className="text-field-cf9 extra-left w-input"
                                            maxLength={256}
                                            name="name-2"
                                            data-name="Name 2"
                                            placeholder="Last name"
                                            id="name-2"
                                            value={lastName}
                                            onChange={(text) => setLastName(text.target.value)}
                                        />
                                        <input type="email"
                                            className="text-field-cf9 w-input"
                                            maxLength={256}
                                            name="Email"
                                            data-name="Email"
                                            placeholder="Email*"
                                            id="Email-7"
                                            required={true}
                                            value={email}
                                            onChange={(text) => setEmail(text.target.value)}
                                        />
                                        <input type="tel"
                                            className="text-field-cf9 extra-left w-input"
                                            maxLength={256}
                                            name="Phone"
                                            data-name="Phone"
                                            placeholder="Phone*"
                                            id="Phone-2"
                                            required={true}
                                            value={phone}
                                            onChange={(text) => setPhone(text.target.value)}
                                        />
                                    </div>
                                    <textarea
                                        data-name="Field 8"
                                        maxLength={5000}
                                        id="Field-8"
                                        name="Field-8"
                                        required={true}
                                        placeholder="Message*"
                                        className="text-field-cf9 big w-input"
                                        value={message}
                                        onChange={(text) => setMessage(text.target.value)}
                                    >
                                    </textarea>
                                    <input
                                        type="submit" value="Send Message" data-wait="Please wait..." onClick={() => { submit() }}
                                        className="button__primary contactus_button submit-button-2 w-button" />
                                    {/* </form> */}
                                    <div className="success-message-cf9 w-form-done" tabIndex={-1} role="region"
                                        aria-label="Email Form success">
                                        <div className="text-block-8">Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="error-message-cf9 w-form-fail" tabIndex={-1} role="region"
                                        aria-label="Email Form failure">
                                        <div>
                                            Oops! Something went wrong while submitting the form. <br />Please refresh and try again.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}