import React from 'react';
import { transformStyle } from "../../styles";
import MAIN_2 from '../../assets/home/stock_woman_desk_working.png';

export default (props) => {
    return (
        <div className="section-divider wf-section" style={{ marginBottom: '40px' }}>
            <div className="div-block-20">
                <div className="columns w-row">
                    <div className="column w-col w-col-6 w-col-stack">
                        <img
                            src={MAIN_2}
                            loading="lazy" width="414"
                            // srcSet="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630698b590a2245315af6d86_unsplash_DUmFLtMeAbQ-p-500.png 500w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630698b590a2245315af6d86_unsplash_DUmFLtMeAbQ-p-800.png 800w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630698b590a2245315af6d86_unsplash_DUmFLtMeAbQ-p-1080.png 1080w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630698b590a2245315af6d86_unsplash_DUmFLtMeAbQ-p-1600.png 1600w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630698b590a2245315af6d86_unsplash_DUmFLtMeAbQ.png 1920w"
                            sizes="(max-width: 767px) 100vw, (max-width: 991px) 376.494140625px, (max-width: 1919px) 39vw, 35vw"
                            alt="" className="image-19" />
                    </div>
                    <div data-w-id="55afad47-88a4-b7fb-cda2-6779211d66e8" style={transformStyle}
                        className="column-2 w-col w-col-6 w-col-stack">
                        <h1 className="heading-13">Stay productive, stay effective<br /></h1>
                        <div className="text-block-7">Grow your topline revenue, reduce denials and make every second count with the help of Astute's powerful automation tools.</div>
                        <a href="/features" className="button__primary divider-section w-button">Learn
                            more</a>
                    </div>
                </div>
            </div>
        </div>
    )
}