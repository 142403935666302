import React from 'react';
import CLOUD_SERVICES_SVG from '../../assets/svgs/cloud-services.svg';
import SECURE_SVG from '../../assets/svgs/secure.svg';
import INTUITIVE_SVG from '../../assets/svgs/intuitive.svg';
import INTEGRATION_SVG from '../../assets/svgs/integrartion.svg';
import { transformStyle } from "../../styles";

export default (props) => {
    return (
        <div className="core-titles-section wf-section">
            <div className="features_component">
                <div className="features_item">
                    <div className="features_icon-wrapper">
                        <img src={CLOUD_SERVICES_SVG}
                            loading="lazy" alt="" className="icon-1x1-medium" />
                    </div>
                    <div className="margin-bottom margin-small">
                        <h3 className="text-size-xxlarge">Cloud Based</h3>
                    </div>
                    <p data-w-id="b00f5d7c-b4e8-5f99-c001-f681b375b0d4"
                        style={transformStyle}
                        className="text-size-medium">Access your data from anywhere with an internet connection</p>
                </div>
                <div className="features_item-divider"></div>
                <div className="features_item">
                    <div className="features_icon-wrapper">
                        <img
                            src={SECURE_SVG}
                            loading="lazy" alt="" className="icon-1x1-medium" />
                    </div>
                    <div className="margin-bottom margin-small">
                        <h3 className="text-size-xxlarge">Secure<br /></h3>
                    </div>
                    <p data-w-id="b00f5d7c-b4e8-5f99-c001-f681b375b0de" style={transformStyle} className="text-size-medium">Your
                        data is protected in HIPAA compliant data centers</p>
                </div>
                <div className="features_item-divider"></div>
                <div className="features_item">
                    <div className="features_icon-wrapper">
                        <img
                            src={INTUITIVE_SVG}
                            loading="lazy" alt="" className="icon-1x1-medium" />
                    </div>
                    <div className="margin-bottom margin-small">
                        <h3 className="text-size-xxlarge">Intuitive</h3>
                    </div>
                    <p data-w-id="b00f5d7c-b4e8-5f99-c001-f681b375b0e7" style={transformStyle} className="text-size-medium">Get up
                        and running quickly with our easy to use platform</p>
                </div>
                <div className="features_item-divider"></div>
                <div className="features_item">
                    <div className="features_icon-wrapper">
                        <img
                            src={INTEGRATION_SVG}
                            loading="lazy" alt="" className="icon-1x1-medium integration" />
                    </div>
                    <div className="margin-bottom margin-small">
                        <h3 className="text-size-xxlarge">Integrated</h3>
                    </div>
                    <p data-w-id="e8c0f71b-e581-2595-e660-7d2145f5f16f" style={transformStyle} className="text-size-medium">
                        Everything built in<br />to simplify your work</p>
                </div>
            </div>
        </div>
    )
}