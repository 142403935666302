import { Home } from '../components';
export default (props) => {
    return (
        <div>
            <Home.Header />
            {/* <Home.CoreTitles /> */}
            <Home.DividerSection />
            <Home.MainSection />
            <Home.CoreTitles />
            <Home.DividerSection2 />
            {/* <Home.AboutUs /> */}
        </div>
    )
}