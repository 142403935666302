import React from 'react';
import { transformStyle } from "../../styles";

export default (props) => {
    return (
        <div className="section-standard home__unsee wf-section" style={{ marginBottom: '40px' }}>
            <h1 className="heading-14">Why practices choose <span className="text-span-35">Astute Health</span></h1>
            <div className="div-block-23">
                <div className="text_left">Our <span className="text-span-11">modern</span>, <span
                    className="text-span-12">intuitive</span> physical therapy software is the single platform for your
                    <span className="text-span-13">entire team</span>
                </div>
            </div>
            <div className="div-standard highlight-1 first-highlight div_standard_front">
                <div className="giant-testimonial-block first-testimonial w-clearfix">
                    {/* IMAGE EMBEDDED IN CSS */}
                    <div className="giant-testimonial-image highlight-1 home_frontdesk"></div>
                    <div data-w-id="01527143-e32f-4f0b-fd6c-d8a6e3135e09"
                        style={transformStyle}
                        className="testimonial-block-copy">
                        <h2 className="section3__h2-copy">Billers + Coders - Post Claims with Confidence</h2>
                        <img
                            src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/62fd5aa671a17faae4fce8f7_Line.svg"
                            loading="lazy"
                            style={transformStyle}
                            data-w-id="01527143-e32f-4f0b-fd6c-d8a6e3135e0c" alt="" className="image-8 line__home" />
                        <p className="paragraph-3-copy _20px p3_copy_tablet">No two chart notes are 100% alike.
                            Astute Health makes finding the optimal diagnosis and billing codes quick and easy,
                            regardless of your EMR vendor.
                        </p>
                    </div>
                </div>
            </div>
            <div className="div-standard div_standard_ther">
                <div className="giant-testimonial-block w-clearfix">
                     {/* IMAGE EMBEDDED IN CSS */}
                    <div className="giant-testimonial-image highlight-2 home_therapist mobile_image_therapist"></div>
                    <div data-w-id="01527143-e32f-4f0b-fd6c-d8a6e3135e11" style={transformStyle}
                        className="testimonial-block highlight-2-copy-copy">
                        <h2 className="section3__h2-copy">Providers - Never Fall Behind Again</h2>
                        <img
                            src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/62fd5aa671a17faae4fce8f7_Line.svg"
                            loading="lazy"
                            style={transformStyle}
                            data-w-id="01527143-e32f-4f0b-fd6c-d8a6e3135e14" alt="" className="image-8 line__home" />
                        <p className="paragraph-3-copy _20px p3_copy_tablet">You didn’t enter medicine to pore over and fix
                            deficient SOAP notes! Astute Health helps you write concisely at point of care to ensure proper payment.
                            Spend less time staring at screens and more time with your patients.
                        </p>
                    </div>
                    <div className="giant-testimonial-image highlight-2 home_therapist"></div>
                </div>
            </div>
            <div className="div-standard last_div">
                <div className="giant-testimonial-block owners_card_mobile w-clearfix">
                    {/* IMAGE EMBEDDED IN CSS */}
                    <div className="giant-testimonial-image highlight-3 home_oners2"></div>
                    <div data-w-id="01527143-e32f-4f0b-fd6c-d8a6e3135e1b"
                        style={transformStyle}
                        className="testimonial-block highlight-3-copy-copy mobile_card">
                        <h2 className="section3__h2-copy">Practice Managers - Know Your Numbers</h2>
                        <img
                            src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/62fd5aa671a17faae4fce8f7_Line.svg"
                            loading="lazy"
                            style={transformStyle}
                            data-w-id="01527143-e32f-4f0b-fd6c-d8a6e3135e1e" alt="" className="image-8 line__home" />
                        <p className="paragraph-3-copy _20px p3_copy_tablet">Know your Net Collection Rate and High-risk Account
                            Receivables? Across  Astute Health makes it easy to keep a real-time pulse on clinical productivity and
                            billing operations across your unique payer mix.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}