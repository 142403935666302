import React from 'react';
import instaSvg from '../assets/svgs/insta.svg';
import linkedInSvg from '../assets/svgs/linkedin.svg';
import facebookSvg from '../assets/svgs/facebook.svg';
import twitterSvg from '../assets/svgs/twitter.svg';
import LOGO_SVG from '../assets/svgs/Astute Health Main Logo.svg';

export default (props) => {

  const socialMediaIcons = () => {
    return (
      <div className="div-block-8"><a href="#" className="link-block-3 w-inline-block">
        <img
          src={instaSvg}
          loading="lazy" alt="Instagram" className="image-5" /></a>
        <a href="https://www.linkedin.com/company/astutehealth/" target="_blank"
          className="link-block-4 w-inline-block">
          <img
            src={linkedInSvg}
            loading="lazy" alt="Linkedin" className="image-6" /></a>
        <a href="#" className="link-block-2 w-inline-block">
          <img
            src={facebookSvg}
            loading="lazy" alt="Facebook" className="image-7" /></a>
        <a href="#" className="link-block w-inline-block">
          <img
            src={twitterSvg}
            loading="lazy" alt="Twitter" className="image-4" />
        </a>
      </div>
    )
  }

  return (
    // set background to transparent
    <div className="footer wf-section" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <div className="div-block-6">
        <div className="div-block-7">
          <img
            src={LOGO_SVG}
            loading="lazy" width="254" alt="Astute Health" className="image-3" />
        </div>
        <div className="div-block-12">
          {/* TODO: once we have it {socialMediaIcons()} */}
          <div className="div-block-40">
            <a href="/terms-of-service" target="_blank" className="link-2">Terms of service</a>
            <a href="/privacy-policy" target="_blank" className="link">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  )
}
