import React from 'react';
import { transformStyle } from "../../styles";

export default (props) => {
    return (
        <div className="section wf-section" style={{ marginBottom: '0px' }}>
            <div data-w-id="cb68e326-4c52-4efd-c9b3-c2bac90a463b"
                style={transformStyle}
                className="div-block-9">
                <div className="div-block-10">
                    <h2 className="heading-9">Spend more <span className="text-span-5">time</span> with your patients, not your computer.</h2>
                    <a href="/contact-us" className="button__primary divider-section w-button">Request a  demo</a>
                </div>
            </div>
        </div>
    )
}