import Header from "./Header";
import CoreTitles from './CoreTitles';
import DividerSection from './DividerSection';
import MainSection from "./MainSection";
import DividerSection2 from './DividerSection2';
import AboutUs from "./AboutUs";
export default {
    Header,
    CoreTitles,
    DividerSection,
    MainSection,
    DividerSection2,
    AboutUs
}