import React from 'react';
import MAIN_IMAGE from '../../assets/features/stock_medical_billing.png'

export default (props) => {
    return (
        <div className="header-section wf-section">
            <div className="w-layout-grid header-grid">
                <div className="header-content">
                    <h1 className="heading heading_features">Focus on what <span className="text-span">matters</span></h1>
                    <h4 className="heading-2 home__heading">Astute Health's powerful automation and simple interface allows you to
                        focus on delivering great outcomes</h4>
                    <a href="/contact-us" className="button__primary button__header features__heading w-button">Request a demo</a>
                </div>
                {/* <img
                    src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fbda2866242219f88920f_Background%203.svg"
                    loading="lazy" width="645" alt="" className="image-34" /> */}
                <img
                    src={MAIN_IMAGE}
                    loading="lazy" width="632" id="w-node-c2a62407-cb68-7c41-73c3-116d56b60b7b-46c6d56a"
                    sizes="(max-width: 479px) 80vw, (max-width: 991px) 60vw, (max-width: 1919px) 40vw, 650px"
                    // srcSet="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fc7b0050f11770bb61bc9_Dashboard-p-500.png 500w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fc7b0050f11770bb61bc9_Dashboard-p-800.png 800w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fc7b0050f11770bb61bc9_Dashboard-p-1080.png 1080w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fc7b0050f11770bb61bc9_Dashboard-p-1600.png 1600w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fc7b0050f11770bb61bc9_Dashboard-p-2000.png 2000w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fc7b0050f11770bb61bc9_Dashboard-p-2600.png 2600w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630fc7b0050f11770bb61bc9_Dashboard.png 2874w"
                    alt="Dashboard" className="image-12" />
            </div>
            <div className="backgrounds_max-width">
                <div className="purple-background"></div>
                <div className="green-background"></div>
            </div>
            <div className="backgrounds_max-width features_background_circles">
                <div className="purple-background"></div>
                <div className="green-background"></div>
            </div>
            <div className="backgrounds_max-width features_background_circles background2">
                <div className="purple-background purple2"></div>
                <div className="green-background green3-copy"></div>
            </div>
            <div className="backgrounds_max-width features_background_circles background3">
                <div className="purple-background"></div>
                <div className="green-background green3"></div>
            </div>
        </div>
    )
}