const transformStyle = {
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
    transformStyle: 'preserve-3d'
}

const opacityStyle = {
    // this was set to zero I wonder if there is javascript transforming items on this
    opacity: 1
}

const displayNoneStyle = {
    display: "none"
}

export {
    transformStyle,
    opacityStyle,
    displayNoneStyle
}